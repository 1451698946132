.edit-content{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.edit-txtfield{
    width: 300px;
}
.edit-complete-btn,
.edit-complete-btn:hover{
    background-color: #002D82;
}
.edit-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.edit-title{
    width: 100px;
}