.areaBtn {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    border: none;
}
.navBarBtn{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    background-color: #f3f3f3;
    border: none;
}
.navBarBtn:active{
    color: #3B4890;
}
.row{
    display: flex;
}
.txtBtn{
    border: none;
    background-color: transparent;
}
.homeBanner{
    border-radius: 18px;
    width:calc(100% - 16px - 16px);
    height: 80px;
    background-color: grey;
}