@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

body {
 /* overflow: hidden; */
 width: 100vw;
 padding: 0;
  margin: 0;
  font-family: 'Pretendard', sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: 'Pretendard', sans-serif !important;
  font-size: 14px;
}
html{
  margin: 0;
  padding: 0;
}
@keyframes borderSpin {
  0% {
    border-color: transparent #002D82 transparent transparent;
  }
  
  25% {
    border-color: transparent transparent #002D82 transparent;
  }
  50% {
    border-color: transparent transparent transparent #002D82;
  }
  75% {
    border-color: #002D82 transparent transparent transparent;
  }
  100% {
    border-color: transparent #002D82 transparent transparent;
  }
}
.borderAnimation {
  width: 300px;
  height: 300px;
  border: 6px solid transparent;
  border-radius:6px;
  animation: borderSpin 2s linear infinite;
  position: absolute;
}
tr{
  
  /* cursor: pointer; */
}
tr:hover{
  /* background-color: #dfe2e8; */
}
input[type='datetime-local'] {
  border: none; 
  position: relative;
  width: 100%;
  /* background: url(./images/) no-repeat right 10px center /
    35px auto; */
  /* background-color: #bbbbbb; */
  /* box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2); */
 background-color: #F2F3F3;
 /* padding:12px; */
 height: 56px;
  border-radius: 10px;
  text-align: center;
  font-size: 100%;
  margin-bottom: 20px;
}
input[type='datetime-local']::before {
  content: attr(placeholder);
  width: 100%;
  height: 100%;
}
input[type='datetime-local']:valid::before {
  display: none; 
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent; 
  color: transparent; 
  cursor: pointer;
}
/* 스크롤 너비때문에 약간 깨짐 */
table thead
, table tbody tr
{
  display: table;
  width: 100%;
}
code {
  width: 100vw;
  height: 100vh;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
