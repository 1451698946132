.App {

    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    /* overflow-x: scroll; */
}
.BoardListTile{
    border-bottom:1px solid grey;
    padding-top: 8px;
    padding-bottom: 8px;
}
